import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgChartsModule } from 'ng2-charts';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';

import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';

import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import { ApiModule as ActivityLoggingApiModule } from './services/activitylogging';
import { ApiModule as AnalyticsApiModule } from './services/analytics';
import {
    apiActivitylogging, apiAnalytics, apiConditionalAccess, apiFileStore, apiIdentity, apiMaster, apiMigration, apiNotification, apiOrganization, apiReport, apiUser
} from './services/common/configuration.service';
import { ApiModule as ConditionalAccessApiModule } from './services/conditional-access';
import { ApiModule as FileStoreApiModule } from './services/fileStore';
import { ApiModule as IdentityApiModule } from './services/identity';
import { ApiModule as MasterApiModule } from './services/master';
import { ApiModule as MigrationApiModule } from './services/migration';
import { ApiModule as NotificationApiModule } from './services/notification';
import { ApiModule as OrganizationApiModule } from './services/organization';
import { ApiModule as ReportApiModule } from './services/report';
import { CustomTranslateLoader } from './services/translate/custom.translate.loader';
import { ApiModule as ApiUserModule } from './services/user';

import '@angular/common/locales/global/ar';
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/fr';
import { AppSettingsService } from './services/common/appsettings.service';

import { APP_BASE_HREF, CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { AuthService } from './authentication/auth.service';
import { LogoutComponent } from './authentication/logout/logout.component';
import { SigninComponent } from './authentication/signin/signin.component';
import { MyApplicationsComponent } from './mypplications/mypplications.component';
import { AuthInterceptor } from './services/common/auth.interceptor';
import { SettingsService } from './services/common/settings.service';
import { BreadCrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { SystemStatusComponent } from './system-status/system-status.component';
import { WhatsNewComponent } from './whats-new/whats-new.component'

//Management - Groups
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";
import { NgxIntlTelInputModule } from '../../local_node_modules/ngx-intl-tel-input';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { RegisterWelcomeComponent } from './authentication/register-welcome/register-welcome.component';
import { PolicyModalComponent } from './authentication/registration/policy-modal/policy-modal.component';
import { RegistrationComponent } from './authentication/registration/registration.component';

import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { LaddaModule } from 'angular2-ladda';

import { CookieService } from 'ngx-cookie-service';
import { EnforceEnrollmentComponent } from './authentication/enforce-enrollment/enforce-enrollment.component';
import { EnforceFidoEnrollmentComponent } from './authentication/enforce-fido-enrollment/enforce-fido-enrollment.component';
import { EnforcePushEnrollmentComponent } from './authentication/enforce-push-enrollment/enforce-push-enrollment.component';
import { SecurityQuestionsAnswerComponent } from './authentication/security-questions/security-questions-answer/security-questions-answer.component';
import { SecurityQuestionsConfigureComponent } from './authentication/security-questions/security-questions-configure/security-questions-configure.component';
import { SecurityQuestionsContainerComponent } from './authentication/security-questions/security-questions-container/security-questions-container.component';
import { EnrollmentModule } from './shared/enrollment/enrollment.module';
import { SharedModule } from './shared/shared.module';

//Google Map - 
import { GoogleMapsModule } from '@angular/google-maps';
import { AddApplicationToGroupModalComponent } from './mypplications/add-application-to-group-modal/add-application-to-group-modal.component';
import { DeleteGroupModalComponent } from './mypplications/delete-group-modal/delete-group-modal.component';
import { DeleteMyAppGroupApplicationModalComponent } from './mypplications/delete-my-app-group-application-modal/delete-my-app-group-application-modal.component';
import { EditGroupModalComponent } from './mypplications/edit-group-modal/edit-group-modal.component';
import { NewGroupModalComponent } from './mypplications/new-group-modal/new-group-modal.component';

//modal
import { PhoneValidationModal } from './authentication/registration/phone-validation-modal/phone-validation-modal.component';
import { AdminValidationModal } from './user/admin-validation-modal/admin-validation-modal';
import { APIKeysConfirmModal } from './integration/agents/apikeys/password-compromised-modal/appikeys-confirm-modal';

import { Idle, NgIdleModule } from '@ng-idle/core';
import { TreeviewModule } from 'ngx-treeview';
import { SystemDetailsComponent } from './system-status/system-details/system-details.component';
import { DirectoryDetailsComponent } from './system-status/directory-details/directory-details.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    BreadCrumbComponent,
    SidebarComponent,
    DashboardComponent,
    MyApplicationsComponent,
    SystemStatusComponent,
    SigninComponent,
    LogoutComponent,
    RegistrationComponent,
    PolicyModalComponent,
    RegisterWelcomeComponent,
    ChangePasswordComponent,
    SecurityQuestionsContainerComponent,
    SecurityQuestionsConfigureComponent,
    SecurityQuestionsAnswerComponent,
    EnforcePushEnrollmentComponent,
    EnforceEnrollmentComponent,
    NewGroupModalComponent,
    EditGroupModalComponent,
    AddApplicationToGroupModalComponent,
    DeleteGroupModalComponent,
    DeleteMyAppGroupApplicationModalComponent,
    EnforceFidoEnrollmentComponent,
    AdminValidationModal,
    PhoneValidationModal,
    APIKeysConfirmModal,
    WhatsNewComponent,
    SystemDetailsComponent,
    DirectoryDetailsComponent
  ],
  imports: [
    TreeviewModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    CommonModule,
    MasterApiModule.forRoot(() => apiMaster()),
    AnalyticsApiModule.forRoot(() => apiAnalytics()),
    ApiUserModule.forRoot(() => apiUser()),
    FileStoreApiModule.forRoot(() => apiFileStore()),
    ActivityLoggingApiModule.forRoot(() => apiActivitylogging()),
    NotificationApiModule.forRoot(() => apiNotification()),
    IdentityApiModule.forRoot(() => apiIdentity()),
    OrganizationApiModule.forRoot(() => apiOrganization()),
    ConditionalAccessApiModule.forRoot(() => apiConditionalAccess()),
    MigrationApiModule.forRoot(() => apiMigration()),
    ReportApiModule.forRoot(() => apiReport()),
    EnrollmentModule,
    FormsModule,
    ReactiveFormsModule,
    SettingsService,
    AppRoutingModule,
    NgbModule,
    NgChartsModule,
    NgxQRCodeModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgSelectModule,
    NgxIntlTelInputModule,
    DataTablesModule,
    PerfectScrollbarModule,
    BrowserAnimationsModule,
    SharedModule,
    GoogleMapsModule,
    LaddaModule.forRoot({
      style: 'expand-left',
      spinnerSize: 30,
      spinnerColor: 'white',
      spinnerLines: 12
    }),
    ToastrModule.forRoot({
      timeOut: 3000, // 5 seconds
      closeButton: true,
      progressBar: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxMapboxGLModule.withConfig({
      accessToken: 'TOKEN', // Optionnal, can also be set per map (accessToken input of mgl-map)

      //this line below seems not working in new version of ngx-mapbox-gl library
      //geocoderAccessToken: 'TOKEN' // Optionnal, specify if different from the map access token, can also be set per mgl-geocoder (accessToken input of mgl-geocoder)
    }),
    NgIdleModule.forRoot()
   
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    AuthService,
    AppSettingsService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    CookieService,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
