export * from './agent.service';
import { AgentService } from './agent.service';
export * from './applicationSetting.service';
import { ApplicationSettingService } from './applicationSetting.service';
export * from './attributeMapping.service';
import { AttributeMappingService } from './attributeMapping.service';
export * from './attributeMetadata.service';
import { AttributeMetadataService } from './attributeMetadata.service';
export * from './department.service';
import { DepartmentService } from './department.service';
export * from './deploymentDetail.service';
import { DeploymentDetailService } from './deploymentDetail.service';
export * from './expression.service';
import { ExpressionService } from './expression.service';
export * from './group.service';
import { GroupService } from './group.service';
export * from './groupApplication.service';
import { GroupApplicationService } from './groupApplication.service';
export * from './groupDirectory.service';
import { GroupDirectoryService } from './groupDirectory.service';
export * from './licence.service';
import { LicenceService } from './licence.service';
export * from './loggerSetting.service';
import { LoggerSettingService } from './loggerSetting.service';
export * from './messageTemplate.service';
import { MessageTemplateService } from './messageTemplate.service';
export * from './msp.service';
import { MspService } from './msp.service';
export * from './organization.service';
import { OrganizationService } from './organization.service';
export * from './organizationApplication.service';
import { OrganizationApplicationService } from './organizationApplication.service';
export * from './organizationDirectory.service';
import { OrganizationDirectoryService } from './organizationDirectory.service';
export * from './organizationQuestionSecret.service';
import { OrganizationQuestionSecretService } from './organizationQuestionSecret.service';
export * from './organizationSetting.service';
import { OrganizationSettingService } from './organizationSetting.service';
export * from './radiusClient.service';
import { RadiusClientService } from './radiusClient.service';
export * from './radiusMapping.service';
import { RadiusMappingService } from './radiusMapping.service';
export * from './reports.service';
import { ReportsService } from './reports.service';
export * from './safeLocations.service';
import { SafeLocationsService } from './safeLocations.service';
export * from './securitySetting.service';
import { SecuritySettingService } from './securitySetting.service';
export * from './syncServiceHistory.service';
import { SyncServiceHistoryService } from './syncServiceHistory.service';
export * from './theme.service';
import { ThemeService } from './theme.service';
export * from './version.service';
import { VersionService } from './version.service';
export * from './windowsLogon.service';
import { WindowsLogonService } from './windowsLogon.service';
export const APIS = [AgentService, ApplicationSettingService, AttributeMappingService, AttributeMetadataService, DepartmentService, DeploymentDetailService, ExpressionService, GroupService, GroupApplicationService, GroupDirectoryService, LicenceService, LoggerSettingService, MessageTemplateService, MspService, OrganizationService, OrganizationApplicationService, OrganizationDirectoryService, OrganizationQuestionSecretService, OrganizationSettingService, RadiusClientService, RadiusMappingService, ReportsService, SafeLocationsService, SecuritySettingService, SyncServiceHistoryService, ThemeService, VersionService, WindowsLogonService];
