/**
 * Conditional Access Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ValidateReason } from './validateReason';


export interface LoginDetails { 
    organizationId?: string | null;
    organizationMnemonic?: string | null;
    username?: string | null;
    ipAddress?: string | null;
    loginDate?: string | null;
    application?: string | null;
    userAgent?: string | null;
    validateReason?: ValidateReason;
}

