import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RoutesRecognized } from '@angular/router';
import { AppSettingsService } from 'src/app/services/common/appsettings.service';
import { UserstateService } from 'src/app/services/user/api/userstate.service';
import { UserModel, UsersService } from '../../services/user'
import { Subscription } from 'rxjs';
import { SystemSettingService } from '../../services/master';
import { ConstantService } from 'src/app/services/common/constant.service';
import { AuthService } from 'src/app/authentication/auth.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {
  public title: string;
  public breadcrumbs: string[] = [];
  subscription: Subscription = new Subscription;
  userInfo = null;
  userStatus = null;
  groupInfo = null;
  groupStatus = null;
  bannerMsg: string = "";
  userMobileNotVerified:boolean = false;

  constructor(
    public appSettings: AppSettingsService,
    private router: Router, 
    public authService: AuthService,
    private userStateService: UserstateService,
    private systemSettingService: SystemSettingService,
    private constantService: ConstantService,
    private usersService:UsersService
  ) {

    router.events.forEach((event: any) => {
      if (event instanceof RoutesRecognized) {
        this.breadcrumbs = [];
        this.createBreadcrumbs(event.state.root);
        this.title = this.breadcrumbs[this.breadcrumbs.length - 1];
        localStorage.setItem('title', this.title);
        localStorage.setItem('breadcrumbs', JSON.stringify(this.breadcrumbs));

        this.fetchInfo(event.url);
      }
    });
  }

  async ngOnInit() {
    this.title = localStorage.getItem('title');
    this.breadcrumbs = JSON.parse(localStorage.getItem('breadcrumbs'));

    this.fetchInfo(this.router.url);
    
    await this.authService.getUser();
    this.getBannerMsg();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  getBannerMsg(){
    this.subscription.add(this.systemSettingService
      .apiSystemSettingMessageGet(this.constantService.MaintenanceWindow)
      .subscribe((data) => {
        this.bannerMsg = data;
      }
    )); 
    this.subscription.add(this.usersService
      .apiUsersCheckUserMobileVerifiedOrganizationIdUserIdGet(this.authService.organizationId,this.authService.user.profile.UserID)
      .subscribe((data) => {
        if(data!=true){
          this.userMobileNotVerified = true;
        }
      }
    ));      
  }

  createBreadcrumbs(route: ActivatedRouteSnapshot): void {
    if (route.data["title"]) {
      this.breadcrumbs.push(route.data["title"]);
    }
    route.children.forEach(child => {
      this.createBreadcrumbs(child);
    })
  }


  fetchInfo(url: string) {
    this.userInfo = null;
    this.userStatus = null;
    this.groupInfo = null;
    this.groupStatus = null;

    if (url.indexOf('/users/user-edit') > -1) {
      let selectedUser = this.userStateService.selectedUser;
      if (selectedUser) {
        let userinfo = selectedUser.firstName;
        if (selectedUser.lastName) {
          userinfo = `${userinfo} ${selectedUser.lastName}`;
        }
        if (userinfo.trim() !== '') {
          this.userInfo = `( ${userinfo} ) `;
        }
        this.userStatus = selectedUser['userStatus']?.toLowerCase();
      }
    } else {
      this.userStateService.selectedUser = {} as UserModel;
    }

    if(url.indexOf('/groups/group-edit') > -1){
      let selectedGroup = this.appSettings.selectedGroup;
      if(selectedGroup){
        let groupinfo = selectedGroup.groupName;
        if(!!groupinfo){
            this.groupInfo = `( ${groupinfo} )`;
            this.groupStatus = selectedGroup.isActive ? 'active' : 'inactive';
        }
      }
    } else {
      this.appSettings.selectedGroup = null;
    }
  }

}
